/// <reference path="o365.pwa.declaration.sw.routehandlers.api.data.export.handler.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiDataExportStrategy } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.strategies.api.data.export.strategy.d.ts')>("o365.pwa.modules.sw.strategies.api.data.export.strategy.ts");

    function initializeApiDataExportRouteHandler() {
        self.o365.registerRoute(
            new RegExp('\/nt(?:\/api\/data\/export)(?:\/.*\.(pdf|xlsx))'),
            new ApiDataExportStrategy({}),
            'POST'
        );
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.routehandlers.api.data.export.handler.d.ts')>({ initializeApiDataExportRouteHandler });
})();
